// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    appKey: 'QTE3MkNFRENBRTQ3NDc0QjYxNUM1NEQ1MTBBNUQ4NEE4REVBMzAzMkU5NTg1ODc0MzBCNDEzNTM4QkUzRjMzM35QdW1tZWxv',

    authenticationUrl: 'https://wksuatapim.azure-api.net/authentication/api/v1',
    tenantUrl: 'https://wksuatapim.azure-api.net/tenant/api/v1',
    teamUrl: 'https://wksuatapim.azure-api.net/team/api/v1',
    subscriptionUrl: 'https://wksuatapim.azure-api.net/subscription/api/v1',
    paymentUrl: 'https://wksuatapim.azure-api.net/payment/api/v1',
    rewardUrl: 'https://wksuatapim.azure-api.net/reward/api/v1',
    offerUrl: 'https://wksuatapim.azure-api.net/offer/api/v1',
    orderUrl: 'https://wksuatapim.azure-api.net/order/api/v1',

    authenticationSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    tenantSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    teamSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    subscriptionSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    paymentSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    rewardSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    offerSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c',
    orderSubscriptionKey: '35cd38491f3642c58e0d667d6a4c285c'
};
